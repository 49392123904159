import React from "react";
import { Box, Text } from "grommet";

const RefundPolicy = () => {
  const policy = `Students who give notice to cancel their enrollment more than 10 days prior to the commencement of a program will be entitled to a full refund of fees paid.\n\n
    Students who give notice to cancel their enrollment less than 10 days prior to the commencement of a program will be entitled to a 90% refund of fees paid. The amount retained (10%) by CodeValley is required to cover the costs of administration and resources which will have already been committed based on the students initial intention to undertake the training.\n\n
    Students who cancel their enrollment within 1 full business day prior to program commencement OR after a the  program has commenced will not be entitled to a refund of fees.\n\n
    Refunds will be paid within thirty (30) days upon notification of cancellation. `;
  return (
    <Box gap="small">
      <Text size="medium" weight="bold">
        Refund Policy
      </Text>
      {policy.split("\n\n").map((text) => {
        return <Text key={text} size="small">{text}</Text>;
      })}
    </Box>
  );
};

export default RefundPolicy;
