import React from "react";
import { Box, Grid, Heading, Text, Image, ResponsiveContext } from "grommet";

import { useHistory } from "react-router-dom";

import Data from "./data.svg";
import Automation from "./automation.svg";
import Marketing from "./marketing.svg";
import AWS from "./aws.svg";

import { useQuery, gql  } from '@apollo/client'
import { SemipolarSpinner } from 'react-epic-spinners'


const GET_COURSES = gql`
query MyQuery {
  courses(order_by: {id: asc}, where: {hide: {_eq: false}}) {
    id
    cost
    focus
    duration
    title
    short_desc
    slug
  }
}
`;

function chooseImage(id) {
  if (id === 1) {
    return Data;
  } else if (id === 2) {
    return Automation;
  } else if (id === 3) {
    return Marketing;
  } else if (id === 4) {
    return AWS;
  }
}

const CourseList = () => {

  let history = useHistory();
  const size = React.useContext(ResponsiveContext);
  const { loading, error, data } = useQuery(GET_COURSES);

  return (
    <Box fill="horizontal" pad="large" margin={{ top: "0px" }}>
      <Heading level={2} size="medium" alignSelf="center">
        Courses
      </Heading>
      
      {loading && <Box fill={true} align="center"><SemipolarSpinner color="#1b1a22"/></Box>}

      <Grid
        columns={{
          count: size === "small" ? 1 : 4,
          size: "auto",
        }}
        gap="medium"
      >
        {data && data.courses && data.courses.length > 0 && data.courses.map((course) => (
            <Box border pad="medium" onClick={() => course.id !== 3 ? history.push("/course/" + course.slug) : null} key={course.title}>
              <Box gap="small">
                <Box height="small" width="small" alignSelf="center">
                  <Image fit="cover" src={chooseImage(course.id)} alt="coverimage"/>
                </Box>
                <Heading level={3} weight="bold">
                  {course.title}
                </Heading>
                <Text weight="bold">{course.duration}</Text>
                <Text>{course.short_desc}</Text>
                <Box alignSelf="end">
                  <Text weight="bold">${course.cost} SGD</Text>
                </Box>
              </Box>
            </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default CourseList;
