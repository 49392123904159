import React from "react";
import { Footer, Text, Box, Anchor } from "grommet";
import { useHistory } from "react-router-dom";
import Emoji from "react-emoji-render";
import { useAuth0 } from "@auth0/auth0-react";

const LayoutFooter = () => {
  let history = useHistory();
  const auth0 = useAuth0();
  const role = localStorage.getItem("role");

  if (!auth0) {
    return null
  }

  return (
    <Footer
      background="brand"
      pad="medium"
      direction="column"
      margin={{ top: "50px" }}
      gap="small"
    >
      <Box margin={{ left: "large" }}>
        <Box align="center" gap="medium" direction="row">
          <Text alignSelf="center" weight="bold">
            CodeValley.SG
          </Text>

          <Text
            margin={{ left: "small" }}
            size="small"
            onClick={() => history.push("/courses/")}
          >
            <Anchor label="Courses" color="white" />
          </Text>

          {!auth0.isAuthenticated && (
            <Text margin={{ left: "small" }} size="small">
              <Anchor
                label="Login/Signup"
                color="white"
                onClick={auth0.loginWithRedirect}
              />
            </Text>
          )}
          {auth0.isAuthenticated && (
            <>
              <Text
                margin={{ left: "small" }}
                onClick={() => history.push("/student/profile")}
                size="small"
              >
                <Anchor label="My Dashboard" color="white" />
              </Text>

              <Text margin={{ left: "small" }} size="small">
                <Anchor
                  label="Logout"
                  color="white"
                  onClick={() =>
                    auth0.logout({ returnTo: window.location.origin })
                  }
                />
              </Text>
            </>
          )}

          <Text
            margin={{ left: "small" }}
            size="small"
            onClick={() => history.push("/contact")}
          >
            <Anchor label="Contact" color="white" />
          </Text>

          {role === "admin" && (
            <Text
              margin={{ left: "small" }}
              size="small"
              onClick={() => history.push("/admin/")}
            >
              <Anchor label="Admin" color="white" />
            </Text>
          )}
        </Box>
      </Box>

      <Box margin={{ left: "large" }}>
        <Text size="small">
          UEN: 201913109R <Emoji text="🏠" /> 7 Temasek Boulevard #12-07 Suntec
          Tower 1 S(038987)
        </Text>
      </Box>
    </Footer>
  );
};

export default LayoutFooter;
