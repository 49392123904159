import React, { useState } from "react";
import { Box, Text, DataTable, Grid, ResponsiveContext, Markdown } from "grommet";
import { CSVLink } from "react-csv";

import { useQuery, gql  } from '@apollo/client'


const Admin = () => {
  const size = React.useContext(ResponsiveContext);
  const [courseSelected, setCourseSelected] = useState(false);

  const GET_ALL_ENROLLMENT = gql`
    query MyQuery {
      sessions(order_by: { deadline: desc }) {
        title
        schedule
        id
        stripeId
        enrollments {
          user {
            contact
            email
            name
          }
        }
        course {
          title
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_ALL_ENROLLMENT, {
    pollInterval: 2000,
  });

  return (
    <Box fill={true} pad="medium" direction="column">
      <Grid columns={size === "small" ? 1 : ["55%", "45%"]} gap="xsmall">
        <AdminClasses
          data={data}
          loading={loading}
          setCourseSelected={setCourseSelected}
        />
        <AdminStudents data={data} courseSelected={courseSelected} />
      </Grid>
    </Box>
  );
};

const AdminStudents = ({ data, courseSelected }) => {
  let studentColumn = [
    {
      property: "name",
      header: <Text>Name</Text>,
    },
    {
      property: "contact",
      header: <Text>Contact</Text>,
    },
    {
      property: "email",
      header: <Text>Email</Text>,
    },
  ];
  let studentData = [];

  const [sort, setSort] = useState({
    property: "name",
    direction: "desc",
  });

  let title = "";

  if (!courseSelected) {
    return null;
  } else {
    data.sessions.forEach((entry) => {
      if (entry.id === courseSelected) {
        title = entry.course.title + " " + entry.title;
        entry.enrollments.forEach((enroll) => {
          studentData.push(enroll.user);
        });
      }
    });
  }

  function arrayToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    return array.reduce((str, next) => {
      str +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return str;
    }, str);
  }

  return (
    <Box>
      <Box pad="small">
        <Text weight="bold" size="large">
          Student List
        </Text>
        <Text size="small">{title}</Text>
        <Text size="small">
          {studentData && studentData.length > 0  &&
            <CSVLink data={arrayToCSV(studentData)}>Export CSV</CSVLink>
          }
        </Text>

        <DataTable
          columns={studentColumn.map((c) => ({
            ...c,
            search:
              c.property === "name" ||
              c.property === "contact" ||
              c.property === "email",
          }))}
          data={studentData}
          step={10}
          sort={sort}
          onSort={setSort}
        />
      </Box>
    </Box>
  );
};

const AdminClasses = ({ data, loading, setCourseSelected }) => {
  let classColumn = [
    {
      property: "courseName",
      header: <Text>Course</Text>,
      size: "small",
    },
    {
      property: "className",
      header: <Text>Class</Text>,
      size: "small",
    },
    {
      property: "schedule",
      header: <Text>Schedule</Text>,
      size: "medium",
      render: datum => (
        <Markdown>
          {datum.schedule}
        </Markdown>
      ),
    },
    {
      property: "stripeId",
      size: "small",
      header: <Text>stripeId</Text>,
    },
  ];

  const [sort, setSort] = useState({
    property: "name",
    direction: "desc",
  });
  let classData = [];

  if (!loading && data && data.sessions && data.sessions.length > 0) {
    data.sessions.forEach((entry) => {
      let temp = {
        className: entry.title,
        courseName: entry.course.title,
        stripeId: entry.stripeId,
        schedule: entry.schedule,
        sessionId: entry.id,
      };
      classData.push(temp);
    });
  }

  return (
    <>
      {!loading && (
        <Box>
          <Box pad="small">
            <Text weight="bold" size="large">
              Class List
            </Text>
          </Box>
          <DataTable
            columns={classColumn.map((c) => ({
              ...c,
              search:
                c.property === "className" ||
                c.property === "courseName" ||
                c.property === "stripeId",
            }))}
            data={classData}
            step={10}
            sort={sort}
            onSort={setSort}
            onClickRow={(event) => {
              setCourseSelected(event.datum.sessionId);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default Admin;
