import React from "react";
import {
  Box,
  Header,
  Heading,
  Text,
  Grid,
  ResponsiveContext,
  Markdown,
} from "grommet";
import { useParams } from "react-router-dom";

import Sessions from "./Sessions";
import { useAuth0 } from "@auth0/auth0-react";

import DataBannerImage from "./data.jpg";
import AutomationBannerImage from "./automation.jpg";
import AWSBannerImage from "./aws.jpg";

import RefundPolicy from "./RefundPolicy";

import { useQuery, gql  } from '@apollo/client'

import styled from "styled-components";
import { SemipolarSpinner } from 'react-epic-spinners'

const CourseBanner = ({ title, subtitle, courseId }) => {
  // Pick BannerImage
  let bannerImage = null;
  if (courseId === 1) {
    bannerImage = DataBannerImage;
  } else if (courseId === 2) {
    bannerImage = AutomationBannerImage;
  } else if (courseId === 4) {
    bannerImage = AWSBannerImage;
  }

  return (
    <Box fill={true} pad="none">
      <Header
        background={"url(" + bannerImage + ")"}
        height="medium"
        fill="horizontal"
      >
        <Box direction="column" gap="small">
          <Heading
            color="white"
            size="large"
            elevation="large"
            level={2}
            margin={{ left: "xlarge" }}
          >
            {title}
          </Heading>
          <Box>
            <Text
              size="large"
              color="white"
              margin={{ left: "xlarge" }}
              elevation="large"
            >
              {subtitle}
            </Text>
          </Box>
        </Box>
      </Header>
    </Box>
  );
};

const CourseContent = ({ content, auth0 }) => {
  const size = React.useContext(ResponsiveContext);
  const StyledP = styled.p`
    width: 85%;
  `;

  return (
    <Box fill={true} pad="small" margin={{ top: "350px" }} wrap={true}>
      <Grid
        columns={{ count: size === "small" ? 1 : 2, size: "auto" }}
        gap="small"
      >
        <Box pad="medium" gap="medium">
          <Text weight="bold">
            Title: <Text weight="normal">{content.title}</Text>
          </Text>
          <Text weight="bold">
            Level: <Text weight="normal">{content.level}</Text>
          </Text>
          <Text weight="bold">
            Focus: <Text weight="normal">{content.focus}</Text>
          </Text>
          <Text weight="bold">
            Fees: <Text weight="normal">${content.cost} SGD</Text>
          </Text>
          <Sessions
            courseId={content.id}
            auth0={auth0}
            downpayment={content.downpayment}
          />
          <RefundPolicy />
        </Box>

        <Box pad="medium" gap="medium">
          <Box gap="medium">
            <Text weight="bold">Course Detail</Text>
            <Text size="medium">
              <Markdown components={{ p: StyledP }}>{content.desc}</Markdown>
            </Text>
          </Box>

          <Box gap="medium">
            <Text weight="bold">Learning Outcomes</Text>
            <Text>At the end of the course, participants will understand:</Text>
            <Box gap="small">
              <Text size="medium">
                <Markdown>{content.learning_outcomes}</Markdown>
              </Text>
            </Box>
          </Box>

          {content.outline && (
            <Box gap="medium">
              <Box gap="small" direction="column">
                <Text size="medium">
                  <Markdown components={{ p: StyledP }}>
                    {content.outline}
                  </Markdown>
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

const GET_COURSE = gql`
  query MyQuery($slug: String!) {
    courses(where: { slug: { _eq: $slug } }) {
      cost
      desc
      downpayment
      duration
      focus
      id
      level
      learning_outcomes
      outline
      short_desc
      title
    }
  }
`;

const CourseDetail = () => {
  let { slug } = useParams();

  const { loading, error, data } = useQuery(GET_COURSE, {
    variables: { slug: slug },
  });
  const auth0 = useAuth0();

  if (!auth0) {
    return null;
  }

  return (
    <Box direction="column" fill={true} gap="small" flex>
      {loading && <Box fill={true} align="center"><SemipolarSpinner color="#1b1a22"/></Box>}
      {!loading && data && data.courses &&
      <>
        <CourseBanner
          title={data.courses[0].title}
          subtitle={data.courses[0].short_desc}
          courseId={parseInt(data.courses[0].id)}
        />
        <CourseContent content={data.courses[0]} auth0={auth0} />
      </>
      }
    </Box>
  );
};

export default CourseDetail;
