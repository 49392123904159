
import { useEffect, useState } from "react";

import { useQuery, gql  } from '@apollo/client'


const GET_SESSIONS_ENROLLED = gql`
  query MyQuery($userId: String!, $sessionId: Int!) {
    enrollment(
      where: { userId: { _eq: $userId }, sessionId: { _eq: $sessionId } }
    ) {
      session {
        schedule
        title
        course {
          title
        }
      }
    }
  }
`;

export function useIsEnrolled(userId, sessionId) {
  const { loading, error, data } = useQuery(GET_SESSIONS_ENROLLED, {
    variables: { userId: userId, sessionId: sessionId },
    pollInterval: 3000,
  });
  const [result, setResults] = useState(false);

  useEffect(() => {
    if (!loading && data && data.enrollment.length > 0) {
      setResults(true)
    }
    if (!loading && data && data.enrollment.length === 0) {
      setResults(false)
    }
  }, [loading, data])

  if (loading) {
    return null
  }

  return result

}

const GET_ENROLLMENT_AGG = gql`
query MyQuery($sessionId: Int!) {
  enrollmentAgg(where: {sessionId: {_eq: $sessionId}}) {
    overcapacity
    sessionId
  }
}
`;

export function useIsSessionFull(sessionId) {
  const { loading, error, data } = useQuery(GET_ENROLLMENT_AGG, {
    variables: { sessionId: sessionId },
    pollInterval: 3000,
  });
  const [result, setResults] = useState(false);

  useEffect(() => {
    if (!loading && data && data.enrollmentAgg.length > 0 && data.enrollmentAgg[0].overcapacity === true) {
      setResults(true)
    } else {
      setResults(false)
    }
  }, [loading, data])

  if (loading) {
    return null
  }

  return result

}

const GET_USER_DETAILS = gql`
query MyQuery($userId: String!) {
  users_by_pk(id: $userId) {
    contact
    name
  }
}
`;

export function useIsDetailsFilled(userId) {
  const { loading, error, data } = useQuery(GET_USER_DETAILS, {
    variables: { userId: userId },
    pollInterval: 3000,
  });
  const [result, setResults] = useState(false);

  useEffect(() => {
    if (!loading && data && data.users_by_pk) {
      let contact = data.users_by_pk.contact
      let name = data.users_by_pk.name
  
      if (contact.length > 0 && name.length > 0) {
        setResults(true)
      } else {
        setResults(false)
      }
    }
  }, [loading, data])

  if (loading) {
    return null
  }

  return result

}
