import React from "react";
import Layout from "./components/layout/Layout";
import { Switch, Route, HashRouter as Router } from "react-router-dom";

import { Box, Grommet, ResponsiveContext } from "grommet";

import HomePage from "./components/home/HomePage";
import Courses from "./components/courses/Courses";
import CourseDetail from "./components/courses/CourseDetail";
import Contact from "./components/contactform/Contact";
import Student from "./components/student/Student";
import AdminClasses from './components/admin/AdminClasses'

import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';

import { withAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { createBrowserHistory } from 'history';

const httpLink = createHttpLink({
  uri: "https://codevalley-hasura.herokuapp.com/v1/graphql",
});

const authLink = setContext((_, { headers }) => {

  const token = localStorage.getItem("token");
  const userid = localStorage.getItem("userid");
  const role = localStorage.getItem("role");

  if (token && userid) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "x-hasura-user-id": userid,
        "x-hasura-role": role ? role : "public",
      }
    }
  }

  if (!token && !userid) {
    return {
      headers: {
        ...headers,
        "x-hasura-role": "public",
      }
    }
  }

})
  

const client = new ApolloClient({
  uri: "https://codevalley-hasura.herokuapp.com/v1/graphql",
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const theme = {
  global: {
    colors: {
      brand: "#1b1a22",
    },
    font: {
      family: "'Roboto-light', Arial, sans-serif",
      size: "14px",
      height: "20px",
    },
    face: `
        @font-face {
          font-family: "Roboto-light";
          src: url("/public/Roboto-Light.ttf") format('truetype');
        }
      `,
  },
};

export const history = createBrowserHistory();

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

function App(props) {

  // Default Value
  localStorage.setItem("userid", "");

  if (props.auth0.isAuthenticated) {
  
    const adminList = ['kianhean.codevalley@gmail.com']
    let role  = 'user'

    if (adminList.indexOf(props.auth0.user.email) > - 1){
      role = 'admin'
    }

    props.auth0.getAccessTokenSilently().then(function (data) {
      const token = data;
      localStorage.setItem("token", token);
      localStorage.setItem("userid", props.auth0.user.sub);
      localStorage.setItem("role", role);
      //console.log(token);
    });
  }

  return (
    <Router history={history}>
      <ApolloProvider client={client}>
        <Grommet theme={theme} full>
          <ResponsiveContext.Consumer>
            {(size) => (
              <Box fill={true} flex>
                <Layout>
                  <Switch>
                    <Route path="/" component={HomePage} exact />
                    <Route path="/courses" component={Courses} />
                    <Route path="/course/:slug" component={CourseDetail} />
                    <Route path="/contact" component={Contact} />
                    <ProtectedRoute path="/student/:section" component={Student} />
                    <ProtectedRoute path="/admin/" component={AdminClasses} />
                  </Switch>
                </Layout>
              </Box>
            )}
          </ResponsiveContext.Consumer>
        </Grommet>
      </ApolloProvider>
    </Router>
  );
}

export default withAuth0(App);
