import React from "react";
import { Box } from "grommet";

import HeaderMenu from "./HeaderMenu"
import Footer from "./Footer"

const Layout = (props) => {

    return (
        <>
            <HeaderMenu />

            <Box direction="row" flex overflow={{ horizontal: "hidden" }} fill={true}>
                {props.children}
            </Box>

            <Footer />

        </>
    )

}

export default Layout
