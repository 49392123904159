import React from "react";
import { Button, Box, Text } from "grommet";
import { loadStripe } from "@stripe/stripe-js";

let stripePromise = ''

if (process.env.REACT_APP_ENV === "DEV") {
  stripePromise = loadStripe(process.env.REACT_APP_PUB_STRIPE_DEV);
} else if (process.env.REACT_APP_ENV === "PROD") {
  stripePromise = loadStripe(process.env.REACT_APP_PUB_STRIPE_PROD);
}

const Checkout = ({ email, stripeId }) => {
  const handleClick = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const successURL = (window.location.href).split("/#")[0] + "/student/profile#/student/profile"
    console.log(successURL)

    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        { price: stripeId, quantity: 1 },
      ],
      mode: "payment",
      successUrl: successURL,
      cancelUrl: window.location.href,
      customerEmail: email,
      clientReferenceId: stripeId
    });

    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.

    // TODO redirect to Error Page
    console.log(error.message)
  };

  return (
    <Box width="medium">
      <Button
        primary
        onClick={handleClick}
        label={<Text size="small">Pay Downpayment</Text>}
        color="rgb(125, 76, 219)"
      />
    </Box>
  );
};

export default Checkout;
