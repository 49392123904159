import React, { useState, useEffect } from "react";
import { Form, FormField, TextInput, Box, Button, TextArea, Text } from "grommet";

const ContactForm = ({ emailLoggedin }) => {
  const [status, setStatus] = useState();
  const [email, setEmail] = useState(emailLoggedin);
  const [message, setMessage] = useState();

  useEffect(() => {
    setEmail(emailLoggedin);
  }, [emailLoggedin]);

  const handleSubmit = () => {
    const xhr = new XMLHttpRequest();
    const data = {
      _replyto: email,
      message: message,
    };

    xhr.open("POST", "https://formspree.io/xnqgonje");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(JSON.stringify(data));
  };

  return (
    <Box width="70%">
      {status === "SUCCESS" && <Box margin={{bottom: "10px"}} background="status-ok" pad="medium"><Text color="white">Thanks! Your Message Has Been Sent! We will get in touch shortly.</Text></Box>}
      {status === "ERROR" && <Box margin={{bottom: "10px"}} background="status-critical" pad="medium"><Text color="white">Oops! There was an error. Please check if your email is valid and try again.</Text></Box>}

      <Form onSubmit={() => handleSubmit()} method="POST">
        <FormField name="email" htmlfor="email" label="Email:">
          <TextInput
            id="email"
            name="email"
            disabled={emailLoggedin ? true : false}
            value={email}
            placeholder="email or contact number"
            onChange={(event) => setEmail(event.target.value)}
          />
        </FormField>

        <FormField name="message" htmlfor="message" label="Message:">
          <Box width="large" height="small">
            <TextArea
              id="message"
              name="message"
              fill
              placeholder="Type your Message Here"
              onChange={(event) => setMessage(event.target.value)}
            />
          </Box>
        </FormField>

        <Box direction="row" gap="medium" width="medium">
          <Button
            type="submit"
            primary
            label="Submit"
            color="rgb(125, 76, 219)"
          />
        </Box>
      </Form>
    </Box>
  );
};

export default ContactForm;
