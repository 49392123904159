import React, { useState } from "react";
import { Box, Text, Tab, Tabs } from "grommet";
import { User, Book } from "grommet-icons";
import { useParams } from "react-router-dom";

import Profile from "./Profile";
import Classes from "./Classes";

const RichTabTitle = ({ icon, label, active }) => (
  <Box direction="row" align="center" gap="xsmall" margin="xsmall">
    {icon}
    <Text size="small" color={active ? "rgb(125, 76, 219)" : "light-5"}>
      <strong>{label}</strong>
    </Text>
  </Box>
);

const Student = () => {

  let { section } = useParams();
  let initState = 0;

  if (section === "profile") {
    initState = 0;
  } else {
    initState = 1;
  }

  const [index, setIndex] = useState(initState);
  const onActive = (nextIndex) => setIndex(nextIndex);

  return (
    <Box fill={true} pad="large">
      <Tabs activeIndex={index} onActive={onActive}>
        <Tab
          title={
            <RichTabTitle
              icon={
                <User color={index === 0 ? "rgb(125, 76, 219)" : "light-4"} />
              }
              label="Profile"
              active={index === 0}
            />
          }
          pad="medium"
        >
          <Profile />
        </Tab>

        <Tab
          title={
            <RichTabTitle
              icon={
                <Book color={index === 1 ? "rgb(125, 76, 219)" : "light-4"} />
              }
              label="Enrolled Classes"
              active={index === 1}
            />
          }
          pad="medium"
        >
          <Classes />
        </Tab>
      </Tabs>
    </Box>
  );
};

export default Student;
