import React from "react";
import { Box, Text, Button, Markdown } from "grommet";

import Checkout from "../checkout/Checkout";

import { useQuery, gql  } from '@apollo/client'
import { useIsEnrolled, useIsSessionFull, useIsDetailsFilled } from "../student/StudentUtils";
import { useHistory } from "react-router-dom";

const Session = ({ content, auth0, downpayment }) => {
  const isEnrolled = useIsEnrolled(
    auth0 && auth0.user && auth0.user.sub,
    content.id
  );

  const isFull = useIsSessionFull(content.id, parseInt(content.capacity));
  const isDetailsFilled = useIsDetailsFilled(auth0 && auth0.user && auth0.user.sub);
  let history = useHistory();

  return (
    <Box border pad="medium" gap="small" elevation="xsmall">
      <Text size="medium" weight="bold">
        {content.title}
      </Text>
      <Text size="medium"><Markdown>{content.schedule}</Markdown></Text>

      {auth0.isAuthenticated &&
        isEnrolled !== null &&
        !isEnrolled &&
        isDetailsFilled &&
        !isFull && (
          <Checkout email={auth0.user.email} stripeId={content.stripeId} />
        )}

      {auth0.isAuthenticated &&
        isEnrolled !== null &&
        !isEnrolled &&
        !isDetailsFilled &&
        !isFull && (
          <Box width="medium">
          <Button
            primary
            label={<Text size="small">Please Fill Up Your Contact&Name Before Reserving</Text>}
            color="rgb(125, 76, 219)"
            onClick={() => history.push("/student/profile")}
          />
        </Box>
        )}

      {auth0.isAuthenticated && isEnrolled !== null && isEnrolled && (
        <Box width="medium">
          <Button
            primary
            disabled
            label={<Text size="small">You are enrolled! Thank You</Text>}
            color="rgb(125, 76, 219)"
          />
        </Box>
      )}

      {!auth0.isAuthenticated && !isFull && (
        <Box width="medium">
          <Button
            primary
            onClick={auth0.loginWithRedirect}
            label={
              <Text size="small">Please Login/Signup Before Reserving</Text>
            }
            color="rgb(125, 76, 219)"
          />
        </Box>
      )}

      {isFull && isEnrolled !== null && !isEnrolled && (
        <Box width="medium">
          <Button
            primary
            disabled
            label={<Text size="small">Session Fully Subscribed</Text>}
            color="rgb(125, 76, 219)"
          />
        </Box>
      )}

      {!isEnrolled && !isFull && (
        <Box pad="small">
          <Text color="red" size="small">
            A deposit of ${downpayment} must be paid to reserve a spot.
          </Text>
        </Box>
      )}
    </Box>
  );
};

const GET_SESSIONS = gql`
  query MyQuery($courseId: Int!, $today: date!) {
    sessions(
      where: { courseId: { _eq: $courseId }, deadline: { _gt: $today } }
    ) {
      schedule
      stripeId
      title
      capacity
      id
    }
  }
`;

const Sessions = ({ courseId, auth0, downpayment }) => {
  const today = new Date().toISOString().slice(0, 10);

  const { loading, error, data } = useQuery(GET_SESSIONS, {
    variables: { courseId: courseId, today: today },
  });

  if (loading) {
    return null;
  }

  return (
    <Box gap="small">
      <Text size="medium" weight="bold">
        Available Sessions
      </Text>
      {data.sessions.length > 0 &&
        data.sessions.map((singleClass) => {
          return (
            <Session
              key={singleClass.title}
              content={singleClass}
              auth0={auth0}
              downpayment={downpayment}
            />
          );
        })}
      {data.sessions.length === 0 && (
        <Box border pad="medium" gap="small" elevation="xsmall">
          <Text>
            No Classes Scheduled At This Time, Please Check Back Later
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default Sessions;
