import React from "react";
import { Header, Heading, Box, Text, Button } from "grommet";
import MainBanner from "./mainbanner.png";
import { useHistory } from "react-router-dom";

const Main = () => {
  let history = useHistory();

  return (
    <Box fill={true} pad="none">
      <Header
        background={"url(" + MainBanner + ")"}
        height="medium"
        fill="horizontal"
      >
        <Box direction="column" gap="small" margin={{ left: "large" }}>
          <Box background={{ color: "brand", opacity: 0.5 }}>
            <Heading
              color="white"
              margin={{ left: "medium" }}
              size="large"
              elevation="large"
            >
              Get a Headstart
            </Heading>
          </Box>
          <Box width="large">
            <Box background={{ color: "brand", opacity: 0.5 }}>
              <Text color="white" margin={{ left: "medium", right:"medium" }} elevation="large">
                Our carefully designed courses are built to accelerate your
                learning with relevant examples to get that headstart into the
                new technology focused knowledge economy.
              </Text>
            </Box>
            <Box
              onClick={() => history.push("/courses/")}
              width="medium"
              height="30px"
              margin={{ left: "medium", top: "medium", bottom: "medium" }}
            >
              <Button
                round={false}
                primary
                label="Our Specialty Courses"
                color="rgb(125, 76, 219)"
              />
            </Box>
          </Box>
        </Box>
      </Header>
    </Box>
  );
};

export default Main;
