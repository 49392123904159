import React from "react";
import { Box, Button, Heading, Grid, ResponsiveContext } from "grommet";
import ContactForm from "./ContactForm";

import { Send, Linkedin } from 'grommet-icons';
import { useAuth0 } from "@auth0/auth0-react";


const Contact = () => {
  const size = React.useContext(ResponsiveContext);
  const { isAuthenticated, user } = useAuth0();

  let email = ''

  if (isAuthenticated) {
    email = user.email
  }

  return (
    <Box fill={true} pad="small" margin={{ top: "0px" }}>
      <Grid gap="small">

        <Box fill={true} pad="medium" gap="medium" margin={{ left: "small" }}>
          <Heading
            size="large"
            elevation="large"
            level={2}
          >
            Contact us.
          </Heading>

          <Box width="medium"><Button primary label="Telegram" icon={<Send/>} href="https://t.me/CodevalleySG" target="_blank" /></Box>
          <Box width="medium"><Button primary label="Linkedin" icon={<Linkedin/>} href="https://www.linkedin.com/company/codevalleysg/" target="_blank" /></Box>

        </Box>

        <Box fill={true} pad="medium" gap="medium">
          <ContactForm 
            emailLoggedin={email}
          />
        </Box>

      </Grid>
    </Box>
  );
};

export default Contact;
