import React from "react";
import { Box, Text, Markdown } from "grommet";

import { useAuth0 } from "@auth0/auth0-react";

import { useQuery, gql  } from '@apollo/client'


const GET_SESSIONS_ENROLLED = gql`
  query MyQuery($userId: String!) {
    enrollment(where: { userId: { _eq: $userId } }) {
      session {
        schedule
        title
        course {
          title
        }
      }
    }
  }
`;

const Classes = () => {

  const { user } = useAuth0()
  const userId = user.sub;

  const { loading, error, data } = useQuery(GET_SESSIONS_ENROLLED, {
    variables: { userId: userId },
    pollInterval: 2000
  });

  if (loading) {
    return null;
  }

  return (
    <Box width="large" pad="medium" direction="column" gap="medium">
      {!loading && data && data.enrollment.length === 0 && (
        <Text>You are not currently enrolled into any classes.</Text>
      )}
      {!loading &&
        data &&
        data.enrollment.length > 0 &&
        data.enrollment.map((session) => {
          return (
            <Box pad="medium" border key={session.session.title}>
              <Text weight="bold" color="neutral-1">
                Deposit Paid - Attendance Confirmed
              </Text>
              <Text weight="bold">
                {session.session.course.title} - {session.session.title}
              </Text>

              <Box pad="medium">
              <Text size="medium"><Markdown>{session.session.schedule}</Markdown></Text>
              </Box>

            </Box>
          );
        })}
    </Box>
  );
};

export default Classes;
