import React from "react";
import {
  Anchor,
  Header,
  Box,
  Image,
  Text,
  ResponsiveContext,
  Menu,
} from "grommet";

import { Menu as MenuIcon } from "grommet-icons";

import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Logo from "./logo.png";
import { useHistory } from "react-router-dom";

const NavMenu = ({ history, auth0 }) => {
  const role = localStorage.getItem("role");
  return (
    <>
      <Text
        margin={{ left: "small" }}
        onClick={() => history.push("/courses/")}
      >
        <Anchor label="Courses" color="white" />
      </Text>

      {!auth0.isAuthenticated && (
        <Text margin={{ left: "medium" }}>
          <Anchor
            label="Login/Signup"
            color="white"
            onClick={auth0.loginWithRedirect}
          />
        </Text>
      )}
      {auth0.isAuthenticated && (
        <>
          <Text
            margin={{ left: "medium" }}
            onClick={() => history.push("/student/profile")}
          >
            <Anchor label="My Dashboard" color="white" />
          </Text>

          <Text margin={{ left: "medium" }}>
            <Anchor
              label="Logout"
              color="white"
              onClick={() => auth0.logout({ returnTo: window.location.origin })}
            />
          </Text>
        </>
      )}

      <Text
        margin={{ left: "medium" }}
        onClick={() => history.push("/contact/")}
      >
        <Anchor label="Contact" color="white" />
      </Text>

      {role === "admin" && (
        <Text
          margin={{ left: "medium" }}
          onClick={() => history.push("/admin/")}
        >
          <Anchor label="Admin" color="white" />
        </Text>
      )}
    </>
  );
};

const NavMenuSmall = ({ history, auth0 }) => {
  let NavItems = [];

  if (!auth0.isAuthenticated) {
    NavItems = [
      { label: "Courses", onClick: () => history.push("/courses/") },
      { label: "Login/Signup", onClick: () => auth0.loginWithRedirect },
      { label: "Contact", onClick: () => history.push("/contact/") },
    ];
  } else {
    NavItems = [
      { label: "Courses", onClick: () => history.push("/courses/") },
      {
        label: "My Dashboard",
        onClick: () => history.push("/courses/"),
      },
      {
        label: "Logout",
        onClick: () => auth0.logout({ returnTo: window.location.origin }),
      },
      { label: "Contact", onClick: () => history.push("/contact/") },
    ];
  }

  return (
    <Box alignSelf="end">
      <Menu
        label="    "
        items={NavItems}
        icon={<MenuIcon size="32px" pad="medium" />}
      />{" "}
    </Box>
  );
};

const HeaderMenu = () => {
  let history = useHistory();
  const size = React.useContext(ResponsiveContext);
  const auth0 = useAuth0()

  return (
    <Header background="brand" pad="small">
      <Box
        direction="row"
        align="center"
        gap="small"
        margin={{ left: "large" }}
      >
        <Link to="/">
          <Image src={Logo} alt="logo" />
        </Link>

        {size !== "small" && <NavMenu history={history} auth0={auth0} />}
        {size === "small" && (
          <NavMenuSmall history={history} auth0={auth0} />
        )}
      </Box>
    </Header>
  );
};

export default HeaderMenu;
