import React from "react";
import { Box, Grid, Heading, Text, ResponsiveContext } from "grommet";

const FAQ = () => {

  const size = React.useContext(ResponsiveContext);

  return (
    <Box fill="horizontal" pad="large" margin={{ top: size === "small" ? "700px" :"300px" }}>
      <Grid
        columns={{
          count: size === "small" ? 1 : 3,
          size: "auto",
        }}
        gap="medium"
      >
        <Box gap="medium">
          <Heading alignSelf="start" size="medium">
            FAQ
          </Heading>
        </Box>
        <Box gap="medium" direction="column">
          <Box>
            <Text weight="bold">Why Choose CodeValley.SG?</Text>
            <Text margin={{ top: "20px" }}>
              CodeValley offers a unique style of pedagogy, where lessons are
              driven by clear objectives for students to achieve. Our innovative
              courses are built around real use cases which students can not
              only expect to complete but also develop an inquisitive mindset to
              approaching any other problems.
            </Text>
          </Box>
          <Box>
            <Text weight="bold">What will be taught?</Text>
            <Text margin={{ top: "20px" }}>
              The lesson objectives will differ depending on the course you have
              signed up for. We have designed all courses to be driven by clear
              objectives and these will be run through during the first lesson.
            </Text>
            <Text margin={{ top: "10px" }}>
              We will also continuously innovate and adapt to the requirements
              of the market and build our objectives towards the evolution of
              tools and technique.
            </Text>
          </Box>
          <Box>
            <Text weight="bold">What is the student to teacher ratio</Text>
            <Text margin={{ top: "20px" }}>
              We want students to feel comfortable to ask for help when in need,
              therefore we ensure that there will be at least 1 instructor to 20
              students or 1 teaching assistant to 10 students.
            </Text>
          </Box>
        </Box>
        <Box gap="medium" direction="column">
          <Box>
            <Text weight="bold">
              I have 0 programming knowledge, can I still attend your courses?
            </Text>
            <Text margin={{ top: "20px" }}>
              Absolutely yes! We have designed our Level 100 courses for
              students who have zero experience. We will teach you how to
              install any relevant software if necessary.
            </Text>
          </Box>
          <Box>
            <Text weight="bold">
              Is there anything I need to attend this course?
            </Text>
            <Text margin={{ top: "20px" }}>
              All you need is a working laptop and your desire to learn. We will
              take care of the rest.
            </Text>
          </Box>
          <Box>
            <Text weight="bold">
              Is there any guarantee for internships or job placements after
              completion of the course?
            </Text>
            <Text margin={{ top: "20px" }}>
              In short, no. However, we will do our best to help you and share
              opportunities from our wide network and industry connections. We
              will also share tips for preparing your resume and interview.
            </Text>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default FAQ;
