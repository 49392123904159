import React from "react";

import Main from './Main'
import CardView from './CardView'
import FAQ from './FAQ'

import { Box } from "grommet";

const HomePage = () => {
    return (
        <Box direction="column" fill={true} gap="large" flex>
            <Main />
            <CardView />
            <FAQ />
        </Box>
    )
}

export default HomePage