import React from "react";

import { Grid, Heading, Box, Text, ResponsiveContext } from "grommet";
import { Cubes, Code, Book } from "grommet-icons";

const CardView = () => {

  const size = React.useContext(ResponsiveContext);

  return (
    <Box fill="horizontal" pad="large" margin={{top: "350px"}}>
      <Heading level={2} size="medium" alignSelf="center">
        What Makes Us Different
      </Heading>

      <Grid
        columns={{
          count: size === "small" ? 1 : 3,
          size: "auto",
        }}
        gap="medium"
      >
        <Box gap="medium">
          <Box alignSelf="center">
            <Cubes color="status-error" size="xlarge" />
          </Box>
          <Text alignSelf="center" size="large" weight="bold">
            Specialized Courses
          </Text>
          <Text alignSelf="center">
            We tailor our courses with a domain in mind so that it is relevant
            and practical.
          </Text>
        </Box>
        <Box gap="medium">
          <Box alignSelf="center">
            <Code color="status-error" size="xlarge" />
          </Box>
          <Text alignSelf="center" size="large" weight="bold">
            Developers First
          </Text>
          <Text alignSelf="center">
            Our instructors and teaching assistants hold full time jobs in the
            technology industry, they spend most of the day deep in code. Learn
            from practitioners.
          </Text>
        </Box>
        <Box gap="medium">
          <Box alignSelf="center">
            <Book color="status-error" size="xlarge" />
          </Box>
          <Text alignSelf="center" size="large" weight="bold">
            Case Driven Instruction
          </Text>
          <Text alignSelf="center">
            Examples are never generic, we are always trying to solve a real
            problem so that you always see the big picture.
          </Text>
        </Box>
      </Grid>
    </Box>
  );
};

export default CardView;
