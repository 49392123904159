import React, { useState, useEffect } from "react";
import { Button, Box, Form, FormField, TextInput, Text } from "grommet";

import { useAuth0 } from "@auth0/auth0-react";

import { useQuery, gql, useMutation  } from '@apollo/client'


const UPDATE_PROFILE = gql`
  mutation MyMutation($id: String!, $name: String!, $contact: String!) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { contact: $contact, name: $name }
    ) {
      id
      contact
      name
    }
  }
`;

const GET_PROFILE = gql`
  query MyQuery($id: String!) {
    users_by_pk(id: $id) {
      contact
      name
      id
    }
  }
`;

const Profile = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [showsave, setShowsave] = useState(false);

  const auth0 = useAuth0()
  const email = auth0.user && auth0.user.email;

  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const { loading, error, data } = useQuery(GET_PROFILE, {
    variables: { id: auth0.user && auth0.user.sub },
    pollInterval: 2000
  });

  // Update Details
  useEffect(() => {
    if (!loading && data && data.users_by_pk && data.users_by_pk) {
      !name && setName(data.users_by_pk.name);
      !number && setNumber(data.users_by_pk.contact);
    }
  }, [loading, data, name, number])

  const handleSubmit = () => {
    let update = updateProfile({
      variables: { id: auth0.user.sub, name: name, contact: number },
    });
    if (!update.loading) {
      setShowsave(true);
    }
  };

  if (!auth0) {
    return null
  }

  return (
    <Box width="large" pad="medium" gap="small">
      {showsave && (
        <Box
          background="status-ok"
          pad="small"
          round="small"
          margin={{ bottom: "10px" }}
        >
          <Text color="white" size="medium" pad="xmsall">
            Profile Information Saved!
          </Text>{" "}
        </Box>
      )}
      {!loading && data && data.users_by_pk && data.users_by_pk && (
        <Form onSubmit={() => handleSubmit()}>
          <FormField name="name" htmlfor="name" label="Name (As Per NRIC)">
            <TextInput
              id="name"
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormField>

          <FormField name="contact" htmlfor="contact" label="Contact Number">
            <TextInput
              id="contact"
              name="contact"
              value={number}
              onChange={(event) => setNumber(event.target.value)}
            />
          </FormField>

          <FormField name="email" htmlfor="email" label="Email">
            <TextInput id="email" name="email" disabled value={email} />
          </FormField>

          <Box
            direction="row"
            width="medium"
            height="40px"
            margin={{ top: "20px" }}
          >
            <Button
              type="submit"
              primary
              label="Save Details"
              color="rgb(125, 76, 219)"
            />
          </Box>
        </Form>
      )}
    </Box>
  );
};

export default Profile;
