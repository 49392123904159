import React from "react";
import CourseList from './CourseList'

const Courses = () => {
  return (
    <CourseList />
  );
};

export default Courses;
